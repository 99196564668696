import { useState } from "react";
import Parallax from "../utility/Parallax";
import Menu from "../utility/Menu";

export default function Contact({ userData, setUserData }) {
    const [message, setMessage] = useState('');
    const [formData, setFormData] = useState({
        name: '',
        message: ''
    });
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        if (formData['name'] === '') { setMessage('Введите имя'); return }

        setFormData({name: '', message: ''});
        // Действия при отправке формы, например, отправка данных на сервер
    };
    
    return (<>
        <Menu userData={userData} setUserData={setUserData}/>

        <div className="contact">
            <img className="border-left click-through" src={require('../images/border_left.png')} alt='border'/>
            
            <span className="login-message">{message}</span>
            <br></br>

            <p>Есть вопросы? Свяжитесь с нами</p>
            <form onSubmit={handleSubmit}>
                <input
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    placeholder="Ваше имя"
                />
                <textarea
                    name="message"
                    value={formData.message}
                    onChange={handleChange}
                    placeholder="Ваше сообщение"
                />
                <button type="submit">Отправить</button>
            </form>
        </div>
        
        <Parallax/>
    </>);
}