// import { MouseParallax } from "react-just-parallax";

export default function Parallax() {
    return <div className="parallax-box">
        {/* <MouseParallax strength={0.01} lerpEase={0.1}> */}
            <img className="parallax-img" src={require("../images/layers/background.png")} alt=""/>
            {/* <MouseParallax strength={0.01} lerpEase={0.1}> */}
                <img className="parallax-img" src={require("../images/layers/carpet.png")} alt=""/>
                {/* <MouseParallax strength={0.01} lerpEase={0.1}> */}
                    <img className="parallax-img" src={require("../images/layers/safe.png")} alt=""/>
                    {/* <MouseParallax strength={0.01} lerpEase={0.1}> */}
                        <img className="parallax-img" src={require("../images/layers/painting.png")} alt=""/>
                        {/* <MouseParallax strength={0.01} lerpEase={0.1}> */}
                            <img className="parallax-img" src={require("../images/layers/tv.png")} alt=""/>
                            {/* <MouseParallax strength={0.01} lerpEase={0.1}> */}
                                <img className="parallax-img" src={require("../images/layers/chair.png")} alt=""/>
                            {/* </MouseParallax> */}
                        {/* </MouseParallax> */}
                    {/* </MouseParallax> */}
                {/* </MouseParallax> */}
            {/* </MouseParallax> */}
        {/* </MouseParallax> */}
    </div>
}