export default function Register({ loading, formData, handleSubmit, handleChange, setFormData, setRegistration, setMessage }) {
    return (<>
        <p>Регистрация</p>
        <form onSubmit={handleSubmit}>
            <input
                type="text"
                name="login"
                value={formData.login}
                onChange={handleChange}
                placeholder="Логин"
                readOnly={loading}
            />
            <input
                type="password"
                name="password"
                value={formData.password}
                onChange={handleChange}
                placeholder="Пароль"
                readOnly={loading}
            />
            <input
                type="password"
                name="password2"
                value={formData.password2}
                onChange={handleChange}
                placeholder="Повторите пароль"
                readOnly={loading}
            />
            <button type="submit">Зарегистрироваться</button>
        </form>
        <br></br>
        <span className={'clickable'} onClick={()=>{
            if (loading) return;

            setFormData({
                login: '',
                password: '',
                password2: '',
            });
            setMessage('');
            setRegistration(false);
        }}>Авторизоваться</span>
    </>);
}