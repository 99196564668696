export default function Login({ loading, formData, handleSubmit, handleChange, setFormData, setRegistration, setMessage }) {
    return (<>
        <p>Авторизация</p>
        <form onSubmit={handleSubmit}>
            <input
                type="text"
                name="login"
                value={formData.login}
                onChange={handleChange}
                placeholder="Логин"
                readOnly={loading}
            />
            <input
                type="password"
                name="password"
                value={formData.password}
                onChange={handleChange}
                placeholder="Пароль"
                readOnly={loading}
            />
            <button type="submit">Войти</button>
        </form>
        <br></br>
        <p>Нет аккаунта?</p>
        <span className={'clickable'} onClick={()=>{
            if (loading) return;

            setFormData({
                login: '',
                password: '',
                password2: '',
            });
            setMessage('');
            setRegistration(true);
        }}>Зарегестрироваться</span>
    </>);
}