import { Outlet } from "react-router-dom";
import HeaderLink from "../../utility/HeaderLink";
import RegularLink from "../../utility/RegularLink";
import User from "../../utility/User";

export default function Main({ userData }) {
    return (<>
        <div className={(userData[0]['account'] === undefined) ? 'background-blur background-blur-active' : 'background-blur'}>
            <div className="link-container-box-row">
                <div className="link-container link-container-row">
                    <HeaderLink className="link" to="/">Главная</HeaderLink>
                    <HeaderLink className="link" to="/webquest">Вебквест</HeaderLink>
                    <HeaderLink className="link" to="/webquest/results">Результаты</HeaderLink>
                </div>
            </div>

            <User userData={userData} className='user-box-webquest'/>

            <Outlet />
        </div>

        {userData[0]['account'] === undefined && <div className="click-blocker"></div>}

        {userData[0]['account'] === undefined && <div className="not-logged-in-window border">
            <span>Похоже вы не авторизованы</span>
            <br></br>
            <span className="clickable"><b><RegularLink to="/login">Авторизоваться</RegularLink></b></span>
            <br></br>
            <span className="clickable"><b><RegularLink to="/">Вернуться на главную</RegularLink></b></span>
        </div>}
    </>);
}