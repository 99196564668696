import { useNavigate } from "react-router-dom";
import { useState } from "react";
import axios from "axios";
import Parallax from "../../utility/Parallax";
import Menu from "../../utility/Menu";
import Login from "./Login";
import Register from "./Register";

//axios.defaults.headers.common['User-Agent'] = 'PostmanRuntime/7.26.2';

export default function Auth({ userData, setUserData }) {
	const navigate = useNavigate();
	const [loading, setLoading] = useState(false);
	const [message, setMessage] = useState('');
	const [registration, setRegistration] = useState(false);
	const [formData, setFormData] = useState({ login: '', password: '', password2: '' });
	
	const handleChange = (e) => {
		let { name, value } = e.target;
		value = value.replace(" ", "").replace(/[\u0250-\ue007]/g, '');
		if (name !== 'password' && name !== 'password2') if (/\d/.test(value[0])) value = value.substr(1);
		e.target.value = value;
		setFormData({...formData, [name]: value});
	};
	async function register(username, password) {
		setLoading(true);
		const object = {
			username: username,
			password: password,
		};
		const response = await axios.post('https://xenabun.ru/api/accounts', object);
		if (response.data.status === 0) {
			setMessage('Аккаунт с таким именем уже существует');
		} else {
			setRegistration(false);
			setMessage('');
		}
		setLoading(false);
	}
	async function login(username, password) {
		setLoading(true);
		const response = await axios.get('https://xenabun.ru/api/accounts.php?username=' + username + '&password=' + password);
		if (response.data.status !== 2) {
			if (response.data.status === 1) {
				let data = [...userData];
				data[0]['account'] = {
					id: response.data.id,
					username: username,
				};
				setUserData(data);
				navigate('/');
			} else {
				setMessage('Введен неверный пароль');
			}
		} else {
			setMessage('Такого аккаунта не существует');
		}
		setLoading(false);
	}
	const handleSubmit = (e) => {
		e.preventDefault();
		if (loading) return;

		if (formData['login'] === '') { setMessage('Введите логин'); return }
		if (formData['password'] === '') { setMessage('Введите пароль'); return }
		if (registration) {
			if (formData['password2'] === '') { setMessage('Подтвердите пароль'); return }
			if (formData['password'] !== formData['password2']) { setMessage('Пароли не совпадают'); return }
		}

		if (registration) {
			register(formData['login'], formData['password']);
		} else {
			login(formData['login'], formData['password']);
		}
	};
	
	return (<>
		<Menu userData={userData} setUserData={setUserData}/>

		{loading && <span className="auth-loading border">Загрузка...</span>}

		<div className="login">
			<img className="border-left click-through" src={require('../../images/border_left.png')} alt='border'/>

			<span className="login-message">{message}</span>
			<br></br>

			{!registration
			? <Login
				loading={loading}
				formData={formData}
				handleSubmit={handleSubmit}
				handleChange={handleChange}
				setFormData={setFormData}
				setRegistration={setRegistration}
				setMessage={setMessage}
			/>
			: <Register
				loading={loading}
				formData={formData}
				handleSubmit={handleSubmit}
				handleChange={handleChange}
				setFormData={setFormData}
				setRegistration={setRegistration}
				setMessage={setMessage}
			/>}
		</div>
		
		<Parallax/>
	</>);
}