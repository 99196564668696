import { useState } from 'react';

const ibase = require('../images/code/base.png');
const ienter = require('../images/code/enter.png');
const iexit = require('../images/code/exit.png');
const inumbers_none = require('../images/code/numbers_none.png');
const inumbers_one = require('../images/code/numbers_one.png');
const inumbers_two = require('../images/code/numbers_two.png');
const inumbers_three = require('../images/code/numbers_three.png');
const inumbers_four = require('../images/code/numbers_four.png');
const inumbers_five = require('../images/code/numbers_five.png');
const inumbers_six = require('../images/code/numbers_six.png');
const inumbers_seven = require('../images/code/numbers_seven.png');
const inumbers_eight = require('../images/code/numbers_eight.png');
const inumbers_nine = require('../images/code/numbers_nine.png');

function Button({ btn, func=()=>{}, funcup=()=>{} }) {
    const x0 = btn[0], y0 = btn[1];
    const x1 = btn[2], y1 = btn[3];
    const width = x1 - x0;
    const height = y1 - y0;
    return <div style={{
        width: width,
        height: height,
        position: 'absolute',
        left: `calc(50vw - 150px + ${x0}px)`,
        top: `calc(50vh - 150px + ${y0}px)`,
        cursor: 'pointer',
        backgroundColor: 'magenta',
        opacity: 0,
    }} onMouseDown={func} onMouseUp={funcup} onMouseLeave={funcup}/>
}

export default function Code({ codeGoal, setCodeGoal, codeLen, codeFunc, setCodeFunc }) {
    //const size = [300, 300];

    const [number, setNumber] = useState(0);
    const [enter, setEnter] = useState(false);
    const [exit, setExit] = useState(false);
    const [code, setCode] = useState('');

    let codeAdd = (n) => {
        if (code.length < codeLen) {
            setCode(code + n);
        }
    }

    return (<div>
        <img className='g-code-img' src={ibase} alt='code'/>
        {!enter && <img className='g-code-img' src={ienter} alt='code'/>}
        {!exit && <img className='g-code-img' src={iexit} alt='code'/>}
        {number === 0 && <img className='g-code-img' src={inumbers_none} alt='code'/>}
        {number === 1 && <img className='g-code-img' src={inumbers_one} alt='code'/>}
        {number === 2 && <img className='g-code-img' src={inumbers_two} alt='code'/>}
        {number === 3 && <img className='g-code-img' src={inumbers_three} alt='code'/>}
        {number === 4 && <img className='g-code-img' src={inumbers_four} alt='code'/>}
        {number === 5 && <img className='g-code-img' src={inumbers_five} alt='code'/>}
        {number === 6 && <img className='g-code-img' src={inumbers_six} alt='code'/>}
        {number === 7 && <img className='g-code-img' src={inumbers_seven} alt='code'/>}
        {number === 8 && <img className='g-code-img' src={inumbers_eight} alt='code'/>}
        {number === 9 && <img className='g-code-img' src={inumbers_nine} alt='code'/>}
        <Button  btn={[150,200,192,223]} func={()=>{
            setEnter(true);
            if (code === codeGoal) {
                setCode('');
                setCodeGoal(null);
                if (codeFunc !== null) {
                    codeFunc();
                    setCodeFunc(null);
                }
            }
        }} funcup={()=>{setEnter(false)}}/>
        <Button btn={[95,199,132,223]} func={()=>{setExit(true); setCode(''); setCodeGoal(null)}} funcup={()=>{setExit(false)}}/>
        <Button btn={[82,88,118,120]} func={()=>{setNumber(1); codeAdd(1)}} funcup={()=>{setNumber(0)}}/>
        <Button btn={[121,87,160,119]} func={()=>{setNumber(2); codeAdd(2)}} funcup={()=>{setNumber(0)}}/>
        <Button btn={[163,88,195,118]} func={()=>{setNumber(3); codeAdd(3)}} funcup={()=>{setNumber(0)}}/>
        <Button btn={[80,123,119,148]} func={()=>{setNumber(4); codeAdd(4)}} funcup={()=>{setNumber(0)}}/>
        <Button btn={[122,123,159,147]} func={()=>{setNumber(5); codeAdd(5)}} funcup={()=>{setNumber(0)}}/>
        <Button btn={[163,122,194,147]} func={()=>{setNumber(6); codeAdd(6)}} funcup={()=>{setNumber(0)}}/>
        <Button btn={[83,153,122,178]} func={()=>{setNumber(7); codeAdd(7)}} funcup={()=>{setNumber(0)}}/>
        <Button btn={[125,152,160,176]} func={()=>{setNumber(8); codeAdd(8)}} funcup={()=>{setNumber(0)}}/>
        <Button btn={[162,152,196,177]} func={()=>{setNumber(9); codeAdd(9)}} funcup={()=>{setNumber(0)}}/>
        <span className='g-code-text'>{code+('0').repeat(codeLen-code.length)}</span>
    </div>);
}