import { useState, useEffect } from "react";

const SECOND = 1000;

export default function Timer(startDate, getUTCDatetime) {
	const [endDate, setEndDate] = useState(null);
	const calcTime = () => {
		if (startDate === null) return 0;
		let now = (endDate !== null) ? endDate : getUTCDatetime();
		return Math.round((now - startDate) / SECOND);
	}
	const [timespan, setTimespan] = useState(calcTime());
	const [active, setActive] = useState(false);

	useEffect(() => {
		setTimespan(calcTime());

		const intervalId = setInterval(() => {
			if (!active || startDate === 0) return;
			setTimespan(calcTime());
		}, SECOND);

		return () => {clearInterval(intervalId)};
	}, [active, startDate, endDate, getUTCDatetime]);

	return {
		timer: timespan,
		timerActive: active,
		activateTimer: setActive,
		_endDate: endDate,
		setEndDate: setEndDate,
	};
}