export default function Layer({ src, opacity='', text, btn, func=()=>{}, funcup=()=>{}, ...props }) {
    let button = (<></>);
    let label = (<></>);
    
    if (btn) {
        const x0 = btn[0], y0 = btn[1];
        const x1 = btn[2], y1 = btn[3];
        const width = x1 - x0;
        const height = y1 - y0;
        button = <div style={{
            width: width,
            height: height,
            left: `${x0}px`,
            top: `calc(11rem + ${y0}px)`,
            position: 'absolute',
            cursor: 'pointer',
            pointerEvents: 'all',
            backgroundColor: 'magenta',
            opacity: 0,
        }} onMouseDown={func} onMouseUp={funcup}/>
    }
    if (text) {
        const x0 = text[1], y0 = text[2];
        const x1 = text[3], y1 = text[4];
        const width  = x1 - x0;
        const height = y1 - y0;
        label = <span style={{
            width: width,
            height: height,
            color: text[6],
            fontSize: `${text[5]}px`,
            lineHeight: `${height}px`,
            left: `${x0}px`,
            top: `calc(11rem + ${y0}px)`,
            pointerEvents: 'none',
            position: 'absolute',
            textAlign: 'center',
        }}>{text[0]}</span>
    }

    return (<>
        <img className={`g-layer ${opacity}`} src={src} alt={'layer'}/>
        {button}
        {label}
    </>);
}