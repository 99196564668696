import RegularLink from "../utility/RegularLink";

export default function Footer() {
    return (<div className="footer">
        <img className="border-footer click-through" src={require('../images/border_footer.png')} alt='border-footer'/>
        <div className="footer-box">
            <div className="footer-block">
                <h3>Помощь</h3>
                <RegularLink to="/contact">Задать вопрос</RegularLink>
            </div>
        </div>
        <p className="footer-copyright">ⓒ Все права защищены</p>
    </div>);
}