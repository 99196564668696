import { useEffect, useState } from "react";
import axios from "axios";
import UserResultTable from "./UserResultTable";
import GlobalResultTable from "./GlobalResultTable";

const parseResult = (result) => {
	if (result === '') return <span className="result-skipped">-</span>
	else if (result === '0') return <span className="result-wrong">×</span>
	else if (result === '1') return <span className="result-right">✓</span>
}
const getEnding1 = (num) => {
	const lastDigit = num % 10;
	if ((num >= 11 && num <= 20)
		|| (lastDigit === 0 || lastDigit >= 5)) return '';
	else if (lastDigit === 1) return 'а';
	else return 'ы';
}
const getEnding2 = (num) => {
	const lastDigit = num % 10;
	if ((num >= 11 && num <= 20)
		|| (lastDigit === 0 || lastDigit >= 5)) return 'ов';
	else if (lastDigit === 1) return '';
	else return 'а';
}
const getPlaytime = (playtimeLength = 0) => {
	const secondsTotal = Math.round(Number(playtimeLength) / 1000);
	const minutesTotal = Math.floor(secondsTotal / 60);
	const hours = Math.floor(minutesTotal / 60);
	const minutes = minutesTotal - (hours * 60);
	const seconds = secondsTotal - (minutesTotal * 60);
	let playtime = '';
	if (hours > 0) playtime += `${hours} Час${getEnding2(hours)} `;
	if (minutes > 0) playtime += `${minutes} Минут${getEnding1(minutes)} `;
	playtime += `${seconds} Секунд${getEnding1(seconds)}`;
	return playtime.trim();
}

export default function Results({ userData }) {
	const [resultPage, setResultPage] = useState('user');
	const [userResults, setUserResults] = useState([]);
	const [userResultsLoaded, setUserResultsLoaded] = useState(false);
	const [globalResults, setGlobalResults] = useState([]);
	const [globalResultsLoaded, setGlobalResultsLoaded] = useState(false);
	const [selectedResult, setSelectedResult] = useState(null);

	useEffect(() => {
		async function getUserResults() {
			if (userData[0]['account'] === undefined) {
				setUserResultsLoaded(true);
				return;
			}

			let response = await axios.get('https://xenabun.ru/api/results/' + userData[0].account.id);
			response.data.forEach(result => {
				result.questions = [];
				result.results = [];
				for (let i = 0; i < 6; i++) {
					result.questions.push(result['question' + (i + 1)]);
					result.results.push(result['result' + (i + 1)]);
					delete result['question' + (i + 1)];
					delete result['result' + (i + 1)];
				}
			});
			setUserResults(response.data);
			setUserResultsLoaded(true);
		}
		async function getGlobalResults() {
			let response = await axios.get('https://xenabun.ru/api/results');
			response.data.forEach(result => {
				result.questions = [];
				result.results = [];
				for (let i = 0; i < 6; i++) {
					result.questions.push(result['question' + (i + 1)]);
					result.results.push(result['result' + (i + 1)]);
					delete result['question' + (i + 1)];
					delete result['result' + (i + 1)];
				}
			});
			setGlobalResults(response.data);
			setGlobalResultsLoaded(true);
		}

		getUserResults();
		getGlobalResults();
	}, [userData]);

	return (<>
		<div className="link-container-box-row results-link-container">
			<div className="link-container link-container-row">
				<div className="link-box">
					<span className={resultPage === 'user' ? "link-selected border" : "link border"} onClick={()=>{
						setSelectedResult(null);
						setResultPage('user');
					}}>Мои результаты</span>
				</div>
				<div className="link-box">
					<span className={resultPage === 'global' ? "link-selected border" : "link border"} onClick={()=>{
						setSelectedResult(null);
						setResultPage('global');
					}}>Общие результаты</span>
				</div>
			</div>
		</div>

		{resultPage === 'user'
		? <UserResultTable
			userResults={userResults}
			userResultsLoaded={userResultsLoaded}
			selectedResult={selectedResult}
			setSelectedResult={setSelectedResult}
			getPlaytime={getPlaytime}
			parseResult={parseResult}
		/>
		: <GlobalResultTable
			globalResults={globalResults}
			globalResultsLoaded={globalResultsLoaded}
			selectedResult={selectedResult}
			setSelectedResult={setSelectedResult}
			getPlaytime={getPlaytime}
			parseResult={parseResult}
		/>}
	</>);
}