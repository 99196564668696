import { useNavigate } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import axios from "axios";
import Layer from "../../game/Layer";
import Letter from "../../game/Letter";
import Code from "../../game/Code";
import Question from "../../game/Question";
import Timer from "../../utility/Timer";

const ibackground = require('../../images/layers/background.png');
const ibackground_opened = require('../../images/layers/background_opened.png');
const icell1 = require('../../images/layers/cell1.png');
const icell1_opened = require('../../images/layers/cell1_opened.png');
const icell2 = require('../../images/layers/cell2.png');
const icell2_opened = require('../../images/layers/cell2_opened.png');
const icell3 = require('../../images/layers/cell3.png');
const icell3_opened = require('../../images/layers/cell3_opened.png');
const ikey_cell = require('../../images/layers/key_cell.png');
const ikey_carpet = require('../../images/layers/key_carpet.png');
const icarpet_opened = require('../../images/layers/carpet_opened.png');
const icarpet = require('../../images/layers/carpet.png');
const isafe = require('../../images/layers/safe.png');
const isafe_opened = require('../../images/layers/safe_opened.png');
const iremote = require('../../images/layers/remote.png');
const ipainting = require('../../images/layers/painting.png');
const ipainting_moved = require('../../images/layers/painting_moved.png');
const itv = require('../../images/layers/tv.png');
const itv_screen = require('../../images/layers/tv_screen.png');
const ichair = require('../../images/layers/chair.png');

const iborder_game = require('../../images/border_game.png');

function getUTCDatetime() {
	let date = new Date();
	const miliseconds = date.getTime();
	const timezoneDiff = date.getTimezoneOffset();
	const ticks = Math.floor(miliseconds + (timezoneDiff * 60000));
	return ticks;
}

export default function Game({ userData, setUserData }) {
	//const size = [500, 400];
	const navigate = useNavigate();

	const code_cell1 = useRef('');
	const code_cell1_len = 4;
	const code_cell3 = useRef('');
	const code_cell3_len = 3;
	const questionAmount = 6;

	const [statesLoaded, setStatesLoaded] = useState(false);
	const [resultsLoading, setResultsLoading] = useState(false);
	const [_startDate, _setStartDate] = useState(null);
	let { timer, timerActive, activateTimer, _endDate, setEndDate } = Timer(_startDate, getUTCDatetime);
	const [finished, setFinished] = useState(false);
	
	const [letter, setLetter] = useState('');
	const [codeGoal, setCodeGoal] = useState(null);
	const [codeLen, setCodeLen] = useState(0);
	const [codeFunc, setCodeFunc] = useState(null);
	const [question, setQuestion] = useState(null);
	const [questionFunc, setQuestionFunc] = useState(null);

	const [carpet, scarpet] = useState(false);
	const [key_carpet, skey_carpet] = useState(false);
	const [painting, spainting] = useState(false);
	const [cell1, scell1] = useState(false);
	const [cell2, scell2] = useState(false);
	const [cell3, scell3] = useState(false);
	const [key_cell, skey_cell] = useState(false);
	const [safe, ssafe] = useState(false);
	const [tv_noise, stv_noise] = useState(false);
	const [tv, stv] = useState(false);
	const [door, sdoor] = useState(false);

	function setResult(index, result) {
		let data = [...userData];
		data[0].account.game['result' + index] = result ? '1' : '0';
		setUserData(data);
	}
	function setStartDate() {
		if (userData[0]['account'] === undefined) return;
		if (userData[0].account['game'] === undefined) return;
		if (userData[0].account.game['startDate'] !== undefined) return;

		const date = getUTCDatetime();
		let data = [...userData];
		data[0].account.game['startDate'] = date;
		setUserData(data);
		_setStartDate(date);
		activateTimer(true);
	}
	function parseTimer(timespan) {
		const minutes = Math.floor(timespan / 60);
		const seconds = timespan - (minutes * 60);
		return ('00' + minutes).slice(-2) + ':' + ('00' + seconds).slice(-2);
	}
	
	async function postResults() {
		const endDate = getUTCDatetime();
		setEndDate(endDate);
		setFinished(true);
		activateTimer(false);
		setResultsLoading(true);
		const object = {
			id: userData[0].account.id,
			playtimeLength: endDate - userData[0].account.game.startDate,
		};
		for (let i = 0; i < questionAmount; i++) {
			object['q' + (i + 1)] = userData[0].account.game['question' + i].id_question;
			object['r' + (i + 1)] = userData[0].account.game['result' + i];
		}
		const response = await axios.post('https://xenabun.ru/api/results', object);
		if (response.data.status === 1) {
			let data = [{account: {
				id: userData[0].account.id,
				username: userData[0].account.username,
			}}];
			setUserData(data);
		}
		setResultsLoading(false);
	}
	async function getQuestions() {
		const response = await fetch('https://xenabun.ru/api/questions', {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				'Authorization': 'Bearer eGVuYWJ1bg==',
			},
		});
		const responseJSON = await response.json();
		const begin = Math.floor(Math.random() * (responseJSON.length - questionAmount));
		let data = [...userData];
		for (let i = 0; i < questionAmount; i++) {
			let question = responseJSON[i + begin];
			question.result = "";
			question.answer = +question.answer;
			question.answers = [];
			for (let i = 0; i < 4; i++) {
				question.answers.push(question['answer' + (i + 1)]);
				delete question['answer' + (i + 1)];
			}
			data[0].account.game['question' + i] = question;
		}
		setUserData(data);
	}

	useEffect(() => {
		if (userData[0]['account'] !== undefined && !statesLoaded) {
			if (userData[0].account['game'] !== undefined) {
				const game = userData[0].account.game;
				code_cell1.current = game.code_cell1;
				code_cell3.current = game.code_cell3;
				scarpet(game.carpet); skey_carpet(game.key_carpet); spainting(game.painting);
				scell1(game.cell1); scell2(game.cell2); scell3(game.cell3); skey_cell(game.key_cell);
				ssafe(game.safe); stv(game.tv); sdoor(game.door);
			}
			setStatesLoaded(true);
		}

		if (userData[0]['account'] !== undefined &&
			userData[0].account['game'] !== undefined &&
			userData[0].account.game['question0'] === undefined) getQuestions();

		if (userData[0]['account'] !== undefined &&
			userData[0].account['game'] !== undefined) {
				if (userData[0].account.game['startDate'] !== undefined) {
					if (_startDate === null) _setStartDate(userData[0].account.game.startDate);
					if (timerActive === false && _endDate === null) activateTimer(true);
				}
			}
	}, [userData]);
	useEffect(() => {
		if (userData[0]['account'] !== undefined && !finished) {
			if (userData[0].account['game'] === undefined) {
				if (code_cell1.current === '') for (let i = 0; i < code_cell1_len; i++) {
					const code_cell1_num = Math.floor(Math.random() * 9) + 1;
					code_cell1.current += code_cell1_num.toString();
				}
				if (code_cell3.current === '') for (let i = 0; i < code_cell3_len; i++) {
					const code_cell3_num = Math.floor(Math.random() * 9) + 1;
					code_cell3.current += code_cell3_num.toString();
				}
			}
			let data = [...userData];
			if (data[0].account['game'] === undefined) data[0].account.game = {};
			data[0].account.game.code_cell1 = code_cell1.current;
			data[0].account.game.code_cell3 = code_cell3.current;
			data[0].account.game.carpet = carpet;
			data[0].account.game.key_carpet = key_carpet;
			data[0].account.game.painting = painting;
			data[0].account.game.cell1 = cell1;
			data[0].account.game.cell2 = cell2;
			data[0].account.game.cell3 = cell3;
			data[0].account.game.key_cell = key_cell;
			data[0].account.game.safe = safe;
			data[0].account.game.tv = tv;
			data[0].account.game.door = door;
			setUserData(data);
		}
	}, [carpet, key_carpet, painting, cell1, cell2, cell3, key_cell, safe, tv, door]);

	return (<>
		<div className={(letter !== '' || codeGoal !== null || question !== null) ? 'background-blur background-blur-active g-container' : 'background-blur g-container'}>
			{!door && <Layer src={ibackground} btn={[5,119,42,286]} func={()=>{
				if (key_cell) {
					setQuestionFunc(_ => result => {
						setResult(5, result);
						sdoor(true);
						postResults();
					});
					setQuestion(userData[0].account.game.question5);
				}
			}}/>}
			{door && <Layer src={ibackground_opened}/>}
			{(painting && !cell1) && <Layer src={icell1} btn={[96,94,145,144]} func={()=>{
				if (key_carpet) {
					setQuestionFunc(_ => result => {
						setResult(2, result);
						scell1(true);
					});
					setQuestion(userData[0].account.game.question2);
				}
			}}/>}
			{cell1 && <Layer src={icell1_opened} btn={[105,120,132,137]} func={()=>{
				setLetter(code_cell1.current);
			}}/>}
			{(painting && !cell2) && <Layer src={icell2} btn={[148,92,198,144]} func={()=>{
				scell2(true);
			}}/>}
			{cell2 && <Layer src={icell2_opened} btn={[160,120,190,137]} func={()=>{
				setLetter('Как твои дела?');
			}}/>}
			{(painting && !cell3) && <Layer src={icell3} btn={[212,105,235,130]} func={()=>{
				setCodeFunc(_ => _ => {
					scell3(true);
				});
				setCodeLen(code_cell3_len);
				setCodeGoal(code_cell3.current);
			}}/>}
			{cell3 && <Layer src={icell3_opened}/>}
			{(cell3 && !key_cell) && <Layer src={ikey_cell} btn={[216,125,236,135]} func={()=>{
				setQuestionFunc(_ => result => {
					setResult(4, result);
					skey_cell(true);
				});
				setQuestion(userData[0].account.game.question4);
			}}/>}
			{(carpet && !key_carpet) && <Layer src={ikey_carpet} btn={[50,356,67,377]} func={()=>{
				skey_carpet(true);
			}}/>}
			{carpet && <Layer src={icarpet_opened}/>}
			{!carpet && <Layer src={icarpet} btn={[15,360,55,395]} func={()=>{
				setStartDate();
				setQuestionFunc(_ => result => {
					setResult(0, result);
					scarpet(true);
				});
				setQuestion(userData[0].account.game.question0);
			}}/>}
			{!safe && <Layer src={isafe} btn={[220,242,245,268]} func={()=>{
				setCodeFunc(_ => _ => {
					ssafe(true);
				});
				setCodeLen(code_cell1_len);
				setCodeGoal(code_cell1.current);
			}}/>}
			{safe && <Layer src={isafe_opened}/>}
			{safe && <Layer src={iremote} btn={[226,265,261,281]} func={()=>{
				if (tv) return;
				setQuestionFunc(_ => result => {
					setResult(3, result);
					stv_noise(true);
					setTimeout(_ => {
						stv(true);
						stv_noise(false)
					}, 1000);
				});
				setQuestion(userData[0].account.game.question3);
			}}/>}
			{!painting && <Layer src={ipainting} btn={[85,77,257,195]} func={()=>{
				setStartDate();
				setQuestionFunc(_ => result => {
					setResult(1, result);
					spainting(true);
				});
				setQuestion(userData[0].account.game.question1);
			}}/>}
			{painting && <Layer src={ipainting_moved}/>}
			<Layer src={itv} text={tv && [code_cell3.current.toString(),300,160,425,200,30,'red']}/>
			<Layer opacity={tv_noise ? 'g-layer-opacity-0-5' : 'g-layer-opacity-0'} src={itv_screen}/>
			<Layer src={ichair}/>

			<img className="g-border" src={iborder_game} alt={'border'}/>
		</div>

		<span className={timer !== 0 ? (door ? "g-timer g-timer-finished" : "g-timer") : "g-timer g-timer-notactive"}>{parseTimer(timer)}</span>

		{(door && resultsLoading) && <div className="g-results-box"><span className="g-results border">Загрузка...</span></div>}
		{(door && !resultsLoading) && <div className="g-results-box">
			<span className="g-results clickable" onClick={()=>{
				navigate('/webquest/results');
			}}>Перейти к результатам</span>
		</div>}

		{(letter !== '' || codeGoal !== null || question !== null) && <div className="click-blocker"></div>}

		{letter !== '' && <Letter
			letter={letter}
			setLetter={setLetter}
		/>}
		{codeGoal !== null && <Code
			codeGoal={codeGoal}
			setCodeGoal={setCodeGoal}
			codeLen={codeLen}
			codeFunc={codeFunc}
			setCodeFunc={setCodeFunc}
		/>}
		{question !== null && <Question
			question={question}
			setQuestion={setQuestion}
			questionFunc={questionFunc}
			setQuestionFunc={setQuestionFunc}
		/>}
	</>);
}