export default function UserResultTable({ globalResults, globalResultsLoaded, selectedResult, setSelectedResult, getPlaytime, parseResult,  }) {
    return (<>
        <div className="results-container">
			<span className="results-title">Табилца общих результатов (Топ 10)</span>
			{(globalResultsLoaded === false)
			? <span className="results-loading">Загрузка...</span>
			: <>{globalResults.length === 0
			? <span className="results-loading">Таблица пуста</span>
			: <div className="results-box border">
				<div className="results-result-box">
					<div className="results-result-general">
						<div className="results-column-global"><span>Имя</span></div>
						<div className="results-column-global">Время прохождения</div>
						<div className="results-column-global">Баллы</div>
						<div className="results-column-global"></div>
					</div>
				</div>
				{globalResults.map((result, index) => {
					let scoreTotal = 0;
					for (let i = 0; i < 6; i++) {
						let score = Number(result.results[i]);
						scoreTotal += score;
					}
					
					return <div key={index} className="results-result-box">
						<div className="results-result-general">
							<div className="results-column-global">{result.username}</div>
							<div className="results-column-global">{getPlaytime(result.playtime_length)}</div>
							<div className="results-column-global">{scoreTotal} / {result.questions.length}</div>
							<div className="results-column-global" onClick={()=>{
								if (selectedResult === index) setSelectedResult(null);
								else setSelectedResult(index);
							}}>⋯</div>
						</div>
						<div className={selectedResult === index ? "results-result-info" : "results-result-info results-result-info-collapsed"}>
							<div className="results-result-info-c">
							{result.questions.map((question, index) => {
								return (<div key={index} className="results-result-info-row-box">
									<div className="results-result-info-row">
										<span>{question}</span>
										{parseResult(result.results[index])}
									</div>
								</div>);
							})}
							</div>
						</div>
					</div>
				})}
			</div>}</>}
		</div>
    </>);
}