import { useState } from "react";

export default function Question({ question, setQuestion, questionFunc, setQuestionFunc }) {
    const [answer, setAnswer] = useState(0);
    
    const handleAnswer = (e) => {
        setAnswer(+e.target.getAttribute('answerindex'));
    }

    return (<div className="g-question-container border">
        <div className="g-question-box">
            <span className="g-question-close clickable" onClick={()=>{
                setQuestion(null);
                setQuestionFunc(null);
            }}>X</span>
            <span className="g-question-question">{question.question}</span>
            {question.answers.map((value, index) => {
                if (value !== '') return (<span answerindex={index + 1} key={index}
                    className={answer === (index + 1) ? "g-question-answer-selected" : "g-question-answer"}
                    onClick={handleAnswer}>{value}</span>);
            })}
            <span className="g-question-confirm clickable" onClick={()=>{
                if (answer === 0) return;
                setQuestion(null);
                if (questionFunc !== null) {
                    questionFunc(question.answer === answer);
                    setQuestionFunc(null);
                }
            }}>Ответить</span>
        </div>
    </div>);
}