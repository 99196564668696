import { useState, useEffect } from "react";
import axios from "axios";
import HeaderLink from "../utility/HeaderLink";
import RegularLink from "../utility/RegularLink";
import Prompt from "../utility/Prompt";

export default function Profile({ userData, setUserData }) {
	const [formData, setFormData] = useState({
		username: '',
		password: '',
	});
	const [loading, setLoading] = useState(false);
	const [message, setMessage] = useState('');
	const [prompt, setPrompt] = useState(null);
	const [promptFunc, setPromptFunc] = useState(null);

	const handleChange = (e) => {
		let { name, value } = e.target;
		value = value.replace(" ", "").replace(/[\u0250-\ue007]/g, '');
		if (name !== 'password') if (/\d/.test(value[0])) value = value.substr(1);
		e.target.value = value;
		setFormData({...formData, [name]: value});
	};
	async function updateProfile() {
		const object = {
			username: formData.username,
			password: formData.password,
		};
		setLoading(true);
		const response = await axios.put('https://xenabun.ru/api/accounts.php?id=' + userData[0].account.id, object);
		if (response.data.status !== 0) {
			let data = [...userData];
			data[0].account.username = formData.username;
			setUserData(data);
			setFormData({username:'',password: ''});
			setMessage('');
		} else {
			setMessage('Ошибка');
		}
		setLoading(false);
	}
	async function deleteProfile() {
		setLoading(true);
		const response = await axios.get('https://xenabun.ru/api/accounts.php?id=' + userData[0].account.id + '&delete=true');
		if (response.data.status !== 0) {
			let data = [...userData];
			data[0]['account'] = undefined;
			setUserData(data);
			setMessage('');
		} else {
			setMessage('Ошибка');
		}
		setLoading(false);
	}
	useEffect(() => {
		if (userData[0]['account'] !== undefined && userData[0].account['username'] !== undefined) {
			setFormData({...formData, 'username': userData[0].account.username});
		}
	}, [userData]);

	return (<>
		<div className={(userData[0]['account'] === undefined) ? 'background-blur background-blur-active' : 'background-blur'}>
			<div className="link-container-box-row">
				<div className="link-container link-container-row">
					<HeaderLink className="link" to="/">Главная</HeaderLink>
					<HeaderLink className="link" to="/webquest">Вебквест</HeaderLink>
					<HeaderLink className="link" to="/webquest/results">Результаты</HeaderLink>
				</div>
			</div>

			<div className="profile-container">
				<span className="profile-title">Профиль</span>
				<span className="login-message">{message}</span>
				{userData[0]['account'] !== undefined && <div className="profile-box">
					<div className="profile-line">
						<span>Имя </span>
						<input
							type='text'
							name='username'
							value={formData.username}
							onChange={handleChange}
						/>
						{formData.username !== userData[0].account.username &&
							<span className="clickable" onClick={()=>{
								updateProfile();
							}}>Изменить</span>}
					</div>
					<div className="profile-line">
						<span>Пароль </span>
						<input
							type='password'
							name='password'
							value={formData.password}
							onChange={handleChange}
						/>
						{formData.password.length > 0 &&
							<span className="clickable" onClick={()=>{
								updateProfile();
							}}>Изменить</span>}
					</div>
					<div className="profile-line">
						<div className="clickable" onClick={()=>{
							setPromptFunc(_=>_=>{
								deleteProfile();
							});
							setPrompt('Вы действительно хотите безвозвратно удалить свой профиль?');
						}}>Удалить профиль</div>
					</div>
				</div>}
			</div>
		</div>

		{loading && <span className="auth-loading border">Загрузка...</span>}

		{prompt !== null && <Prompt prompt={prompt} setPrompt={setPrompt} promptFunc={promptFunc} setPromptFunc={setPromptFunc}/>}

		{userData[0]['account'] === undefined && <div className="click-blocker"></div>}

		{userData[0]['account'] === undefined && <div className="not-logged-in-window border">
			<span>Похоже вы не авторизованы</span>
			<br></br>
			<span className="clickable"><b><RegularLink to="/login">Авторизоваться</RegularLink></b></span>
			<br></br>
			<span className="clickable"><b><RegularLink to="/">Вернуться на главную</RegularLink></b></span>
		</div>}
	</>);
}