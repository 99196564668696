import { Routes, Route, Navigate, Outlet } from "react-router-dom";//, Outlet, useLocation
import { useState, useEffect } from "react";
import './utility/App.css';
import Footer from "./utility/Footer";
import Main from "./pages/Main";
import Profile from "./pages/Profile";
import WebquestMain from "./pages/Webquest/WebquestMain";
import WebquestGame from "./pages/Webquest/WebquestGame";
import WebquestResults from "./pages/Webquest/WebquestResults";
import Contact from "./pages/Contact";
import Auth from "./pages/Auth/Auth";

export default function App() {
	// const theLocation = useLocation();
	// let currentLocation = theLocation.pathname.split("/")[1];
	// currentLocation = currentLocation !== '' ? currentLocation : 'Main';
	
	document.title = 'Вебквест';

	const [userData, setUserData] = useState([{}]);

	useEffect(() => {
    	let data = JSON.parse(localStorage.getItem('userData'));
		if (data) setUserData(data);
  	}, []);
	useEffect(() => {
		localStorage.setItem('userData', JSON.stringify(userData));
	}, [userData]);
	// useEffect(() => { console.log(currentLocation);
	// 	//   document.title = `${currentLocation[0].toUpperCase()}${currentLocation.slice(1,)}`
	// 	if (currentLocation !== 'webquest') {
	// 		clearInterval(timerId);
	// 		setTimerId(null);
	// 	}
	// }, [currentLocation]);
	
  	return (<>
		<Routes>
			<Route path="/" element={<>
				<div className="body">
					<Outlet/>
				</div>
				<Footer/>
				<img className="border-screen click-through" src={require('./images/border.png')} alt={'border'}/>
			</>}>
				<Route index element={<Main userData={userData} setUserData={setUserData}/>}/>
				<Route path="/contact" element={<Contact userData={userData} setUserData={setUserData}/>}/>
				<Route path="/login" element={<Auth userData={userData} setUserData={setUserData}/>}/>
				<Route path="/profile" element={<Profile userData={userData} setUserData={setUserData}/>}/>
				<Route path="/webquest" element={<WebquestMain userData={userData}/>}>
					<Route index element={<WebquestGame userData={userData} setUserData={setUserData}/>}/>
					<Route path="/webquest/results" element={<WebquestResults userData={userData}/>}/>
				</Route>
				<Route path="*" element={<Navigate to="/"/>}/>
			</Route>
		</Routes>
  	</>);
}