import { Link, useMatch, useResolvedPath } from "react-router-dom";
import { useState } from "react";

export default function HeaderLink({ children, to, className, submenu, drop, ...props }) {
  let resolved = useResolvedPath(to);
  let match = useMatch({ path: resolved.pathname, end: true });
  if (match) className = "link-selected";
  className += ' border';
  const [ showDropdown, setShowDropdown ] = useState(false);

  return (<div className={drop ? "link-box link-drop" : "link-box"}>
    {submenu
    ? <span
      className={className}
      {...props}
      onMouseEnter={() => {setShowDropdown(true)}}
      onMouseLeave={() => {setShowDropdown(false)}}
    >
      {children}
      <div className={showDropdown ? "link-drop-box" : "link-drop-box link-drop-box-hidden"}>
        {submenu}
      </div>
    </span>
    : <Link
      className={className}
      to={to}
      {...props}
    >
      <span>{children}</span>
    </Link>}
  </div>);
}