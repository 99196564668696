export default function Prompt({ prompt, setPrompt, promptFunc, setPromptFunc }) {
    return (<div className="prompt-container border">
        <div className="prompt-box">
            <span>{prompt}</span>
            <div className="prompt-buttons">
                <span className="prompt-button clickable" onClick={()=>{
                    if (promptFunc !== null) {
                        promptFunc();
                        setPromptFunc(null);
                        setPrompt(null);
                    }
                }}>Да</span>
                <span className="prompt-button clickable" onClick={()=>{
                    setPromptFunc(null);
                    setPrompt(null);
                }}>Нет</span>
            </div>
        </div>
    </div>);
}