import Parallax from "../utility/Parallax";
import Menu from "../utility/Menu";

export default function Main({ userData, setUserData }) {
    
    return (<>
        <Menu userData={userData} setUserData={setUserData}/>

        <Parallax/>
    </>);
}