import { useState } from "react";
import HeaderLink from "../utility/HeaderLink";
import User from "./User";
import Prompt from "./Prompt";

function Logout({ userData, setUserData, setPrompt, setPromptFunc }) {
    return (<div className="link-box"><span className="link border" onClick={()=>{
        setPromptFunc(_=>_=>{
            let data = [...userData];
            data[0]['account'] = undefined;
            setUserData(data);
        });
        setPrompt('Вы действительно хотите выйти?');
    }}>Выйти</span></div>);
}

export default function Menu({ userData, setUserData }) {
    const [prompt, setPrompt] = useState(null);
    const [promptFunc, setPromptFunc] = useState(null);

    return (<nav className="header">
        <img className="border-right click-through" src={require('../images/border_right.png')} alt='border'/>

        {prompt !== null && <Prompt prompt={prompt} setPrompt={setPrompt} promptFunc={promptFunc} setPromptFunc={setPromptFunc}/>}

        <User userData={userData} className='user-box-menu'/>

        <div className="link-container link-container-column">
            <HeaderLink className="link" to="/">Главная</HeaderLink>
            <HeaderLink className="link" to="/webquest">Вебквест</HeaderLink>
            <HeaderLink className="link" to="/contact">Обратная связь</HeaderLink>
            {userData[0]['account'] === undefined
            ? <HeaderLink className="link" to="/login">Войти</HeaderLink>
            : <Logout userData={userData} setUserData={setUserData} setPrompt={setPrompt} setPromptFunc={setPromptFunc}/>}
        </div>
    </nav>);
}